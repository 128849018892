export const breadcrumbItems = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    to: 'brand',
  },
  {
    key: 'wheelsDesign',
    label: 'langkey.breadcrumb-wheels-design',
    to: 'wheelsDesign',
  },
  {
    key: 'wheelsDetails',
    label:'langkey.breadcrumb-wheels-details',
  },
];

export const breadcrumbItemsCertificate = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    to: 'brand',
  },
  {
    key: 'wheelsDesign',
    label: 'langkey.breadcrumb-wheels-design',
    to: 'wheelsDesign',
  },
  {
    key: 'wheelsDetails',
    label:'langkey.breadcrumb-wheels-details',
    to: 'wheelsDetails',
  },
  {
    key: 'wheelsDetailsCertificate',
    label:'langkey.breadcrumb-Certificate',
  },
];

export const tableFieldWheelDesign = [
  { value: 'man', text: 'langkey.article_number', align: 'center', sortable: false },
  { value: 'width', text: 'langkey.inch', align: 'center' },
  { value: 'boltCircle', text: 'langkey.bolt-circle', align: 'center' },
  { value: 'offset', text: 'langkey.offset', align: 'center' },
  { value: 'certificate', text: 'langkey.information', align: 'center', sortable: false },
  { value: 'purchasePrice', text: 'langkey.price', align: 'center' },
  { value: 'function', text: '', align: 'center', sortable: false },
];

export const tableFieldWheelDesignRetailPrice = [
  { value: 'man', text: 'langkey.article_number', align: 'center', sortable: false },
  { value: 'width', text: 'langkey.inch', align: 'center' },
  { value: 'boltCircle', text: 'langkey.bolt-circle', align: 'center' },
  { value: 'offset', text: 'langkey.offset', align: 'center' },
  { value: 'certificate', text: 'langkey.information', align: 'center', sortable: false },
  { value: 'retailPrice', text: 'langkey.netto', align: 'center' },
  { value: 'retailTaxPrice', text: 'langkey.brutto', align: 'center' },
  { value: 'function', text: '', align: 'center', sortable: false },
];

export const tableFieldWheelDesignCertificate = [
  { value: 'carManufacturer', text: 'langkey.rim-config-configurator', align: 'center', sortable: false },
  { value: 'carClass', text: 'langkey.breadcrumb-model', align: 'center', sortable: false },
  { value: 'carType', text: 'langkey.breadcrumb-type', align: 'center', sortable: false },
  { value: 'certificate', text: 'langkey.certificate', align: 'center', sortable: false },
];

export const selectFilterFieldsCertificate = [
  {
    key: 'carManufacturer',
    placeholder: 'langkey.rim-config-configurator',
    singleSelectLabel: 'langkey.manufacturer',
    pluralSelectLabel: 'langkey.manufacturers',
    sortable: true,
  },
  {
    key: 'carClass',
    placeholder: 'langkey.breadcrumb-model',
    singleSelectLabel: 'langkey.model',
    pluralSelectLabel: 'langkey.models',
    sortable: true,
  },
  {
    key: 'carType',
    placeholder: 'langkey.breadcrumb-type',
    singleSelectLabel: 'langkey.type',
    pluralSelectLabel: 'langkey.types',
    sortable: true,
  },
];

export const initSelectedFilterCertificate = {
  carManufacturer: [],
  carClass: [],
  carType: [],
};