<template>
  <section class="rims-config-wheel-design-details">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @on-click-brand="onClickBrand"
      @on-click-wheelsDesign="onClickWheelsDesign"
      @on-click-wheelsDetails="onHideCertificate"
    />
    <v-container>
      <div class="wheel-showroom">
        <div class="wheel-options-slider">
          <swiper
            ref="wheelOptionsSlider"
            class="swiper"
            :options="swiperOption"
            @slide-change="onSlideChange"
          >
            <swiper-slide
              v-for="(slide, index) in getGroupedWheelsByDesignAndColor"
              :key="index"
            >
              <div class="corners">
                <icon-corner
                  v-for="(item, itemIndex) in slideActiveCorners"
                  :key="`${item}${itemIndex}`"
                  :class="item"
                />
              </div>
              <v-card
                rounded="lg"
                @click="onClickSlide(slide)"
              >
                <img v-lazy="getWheelImage(slide.picturePerspective)">
              </v-card>
            </swiper-slide>
          </swiper>
          <div
            class="custom-swiper-button-prev"
            @click="onSlidePrev"
          >
            <icon-arrow-circle />
          </div>
          <div
            class="custom-swiper-button-next"
            @click="onSlideNext"
          >
            <icon-arrow-circle />
          </div>
        </div>
        <threesixty-wheel
          v-if="!loading"
          ref="threeSixty"
          :src="getWheelThreesixtyUrl"
          :frames="getWheelThreesixtyFrames"
          :size="380"
          :one-frame-src="getWheelOneFrameUrl"
          one-frame-size="400x400"
        />
        <v-card
          class="wheel-description"
          rounded="lg"
        >
          <v-card-title class="card__title">
            <img
              v-if="getBrand"
              :src="`https://cdn.jfnet.de/wheellogos/${getBrand.replace(/\s/g, '')}/200x80-cc-xxxxxx.png`"
              class="brand-image"
            >
            <img
              v-else-if="!empty(getSingleWheel)"
              :src="`https://cdn.jfnet.de/wheellogos/${getSingleWheel.manufacturer.replace(/\s/g, '')}/200x80-cc-xxxxxx.png`"
              class="brand-image"
            >
          </v-card-title>
          <v-card-text class="card__content">
            <h6 class="design">
              <span v-if="getBrand">{{ getWheelDesign }}</span>
              <span v-else-if="!empty(getSingleWheel)">{{ getSingleWheel.design }}</span>
            </h6>
            <p class="color">
              <span v-if="getBrand">{{ currentColor ? currentColor : getSingleWheel.color }}</span>
              <span v-else-if="!empty(getSingleWheel)">{{ getSingleWheel.color }}</span>
            </p>
          </v-card-text>
        </v-card>
      </div>
      <v-data-table
        v-if="!isCertificate"
        class="primary-style wheel-design-table"
        :headers="tableFieldWheelDesign"
        :items="tableItems"
        :sort-by="['width', 'boltCircle', 'offset']"
        multi-sort
        :loading="isLoadingWheelDetails"
        hide-default-footer
        disable-pagination
      >
        <template
          v-for="(field, index) in tableFieldWheelDesign"
          v-slot:[`header.${field.value}`]="{ header }"
        >
          <div
            :key="`${field.value}${index}`"
            class="d-flex align-center justify-center"
          >
            <span>{{ $t(header.text) }}</span>
            <div class="icon__sort" />
          </div>
        </template>
        <template v-slot:[`item.width`]="{ item }">
          {{ item.width | wheelWidth }} x {{ item.diameter }}
        </template>
        <template v-slot:[`item.boltCircle`]="{ item }">
          {{ `${item.boltNumber}/${item.boltCircle}` }}
        </template>
        <template v-slot:[`item.certificate`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              depressed
              plain
              class="icon certificate__button"
              @click="onViewCertificate(item)"
            >
              <icon-certificate class="certificate__icon" />
              {{ $t('langkey.certificate') }}
              <icon-caret
                class="caret__icon"
                color="var(--theme-primary)"
              />
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.purchasePrice`]="{ item }">
          {{ item.bestPrice.purchasePrice | price }}
        </template>
        <template v-slot:[`item.retailPrice`]="{ item }">
          {{ item.bestPrice.retailPrice | price }}
        </template>
        <template v-slot:[`item.retailTaxPrice`]="{ item }">
          {{ item.bestPrice.retailTaxPrice | price }}
        </template>
        <template v-slot:[`item.function`]="{ item }">
          <v-btn
            icon
            plain
            @click="addToCart(item)"
          >
            <icon-add-to-cart />
          </v-btn>
        </template>
      </v-data-table>
      <template v-else>
        <base-rimconfig-filter-bar
          @reset="onResetFilter"
        >
          <template #select-group>
            <div class="configurator-car-select-filter-list">
              <base-select-filter-dropdown
                v-for="(selectField, index) in selectFilterFieldsCertificate"
                :key="`select${selectField.key}${index}`"
                v-model="selectedFilter[selectField.key]"
                :is-loading="isLoadingCertificateFilter"
                :placeholder="$t(selectField.placeholder)"
                :select-label="selectedFilter[selectField.key].length > 1 ? selectField.pluralSelectLabel : selectField.singleSelectLabel"
                :option-items="filtersOptions[selectField.key] || []"
                :sortable="selectField.sortable"
                @input="onSelectFilter"
              />
            </div>
          </template>
        </base-rimconfig-filter-bar>
        <v-data-table
          class="primary-style wheel-design-table"
          :headers="tableFieldWheelDesignCertificate"
          :items="filteredWheelCertificates"
          :loading="isLoadingCertificate"
          hide-default-footer
          disable-pagination
        >
          <template
            v-for="(field, index) in tableFieldWheelDesignCertificate"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
          </template>
          <template v-slot:[`item.certificate`]="{ item }">
            <div class="d-flex justify-center">
              <v-btn
                depressed
                plain
                class="icon certificate__button"
                @click="onClickCertificate(item)"
              >
                <icon-certificate class="certificate__icon" />
                {{ $t('langkey.certificate') }}
                <icon-caret
                  class="caret__icon"
                  color="var(--theme-primary)"
                />
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </template>
    </v-container>

    <base-add-to-cart-dialog
      ref="addToCartDialog"
      is-rim-config
      :cart-items="cartItems"
      :select-quantity-table="selectQuantity"
      supplier-source
      kit-matches
      @on-subtract-quantity="onSubtractQuantityCart"
      @on-add-quantity="onAddQuantityCart"
      @on-update-quantity="onUpdateQuantityCart"
      @confirm-and-show-cart="onConfirmAndShowCart"
      @continue-shopping="onContinueShopping"
      @supplier-add-cart="onSupplierAddToCart"
    />
  </section>
</template>

<style lang="scss">
@import '@/styles/pages/rims-config-wheel-design-details.scss';
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import ThreesixtyWheel from '@/components/ThreesixtyWheel';
import IconCertificate from '@/components/icons/IconCertificate';
import IconCaret from '@/components/icons/IconCaret';
import IconCorner from '@/components/icons/IconCorner';
import IconArrowCircle from '@/components/icons/IconArrowCircle';
import {
  breadcrumbItems,
  breadcrumbItemsCertificate,
  tableFieldWheelDesign,
  tableFieldWheelDesignRetailPrice,
  tableFieldWheelDesignCertificate,
  selectFilterFieldsCertificate,
  initSelectedFilterCertificate,
} from './configs';
import { mapState, mapGetters } from 'vuex';
import { getImageBySize } from '@/utils';
import _ from 'lodash';

export default {
  components: {
    RimconfigBreadcrumb,
    ThreesixtyWheel,
    IconCertificate,
    IconCaret,
    IconCorner,
    IconArrowCircle,
  },
  data() {
    return {
      loading: false,
      isCertificate: false,
      selectQuantity: {},
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 3,
        spaceBetween: 8,
        centeredSlides: true,
        // loop: true,
        slideActiveClass: 'active',
        navigation: {
          nextEl: '.custom-swiper-button-next',
          prevEl: '.custom-swiper-button-prev',
        },
      },
      slideActiveCorners: [
        'top-left',
        'top-right',
        'bottom-right',
        'bottom-left',
      ],
      currentSlide: 0,
      currentColor: '',
      isLoadingWheelDetails: false,
      isLoadingCertificate: false,
      cartItems: [],
      tableItems: [],

      // Wheel design certificate table filter
      isLoadingCertificateFilter: false,
      filtersOptions: {},
      selectedFilter: initSelectedFilterCertificate,
    };
  },
  computed: {
    ...mapState({
      priceType: (state) => state.pricing.priceType,
      wheelDesigns: (state) => state.rimconfigBase.wheelDesigns,
      wheelCertificates: (state) => state.rimconfigWheelDesign.wheelCertificates,
      wheelAssignByWheelTag: (state) => state.rimconfigWheelDesign.wheelAssignByWheelTag,
    }),
    ...mapGetters('rimconfigBase', [
      'getGroupedWheelsByDesignAndColor',
    ]),
    breadcrumbItems() {
      if (this.isCertificate) {
        return breadcrumbItemsCertificate;
      } else {
        return breadcrumbItems;
      }
    },
    tableFieldWheelDesign() {
      if (this.priceType === 'retailPrice' || this.priceType === 'retailTaxPrice') {
        return tableFieldWheelDesignRetailPrice;
      } else {
        return tableFieldWheelDesign;
      }
    },
    tableFieldWheelDesignCertificate() {
      return tableFieldWheelDesignCertificate;
    },
    selectFilterFieldsCertificate() {
      return selectFilterFieldsCertificate;
    },
    getBrand() {
      return this.$route.query.brand;
    },
    getSearch() {
      return this.$route.query.search;
    },
    getWheelDesign() {
      return this.$route.query.design;
    },
    getWheelColor() {
      return this.$route.query.color;
    },
    getWheelThreesixtyUrl() {
      if (this.tableItems.length !== 0) {
        return this.tableItems[0].showroomUrlTemplate;
      } else {
        return '';
      }
    },
    getWheelOneFrameUrl() {
      if (this.tableItems.length !== 0) {
        return this.tableItems[0].picturePerspective;
      } else {
        return '';
      }
    },
    getWheelThreesixtyFrames() {
      if (this.tableItems.length !== 0) {
        const showroomAngles = this.tableItems[0].showroomAngles;
        return Array.isArray(showroomAngles) ?
          showroomAngles :
          [];
      } else {
        return [];
      }
    },
    filteredWheelCertificates() {
      const filtered = this.wheelCertificates.filter((item) => {
        return Object.keys(this.selectedFilter).every((key) => {
          if (this.selectedFilter[key].length === 0) {
            return true;
          }

          return this.selectedFilter[key].some((filterEl) => {
            return item[key] === filterEl;
          });
        });
      });
      return filtered;
    },
    getSingleWheel() {
      if (!this.empty(this.getGroupedWheelsByDesignAndColor[0])) {
        return this.getGroupedWheelsByDesignAndColor[0];
      } else {
        return {};
      }
    },
  },
  watch: {
    isCertificate(val) {
      if (val === false) {
        this.onResetFilter();
      }
    },
  },
  created() {
    if (this.getBrand && !this.getWheelDesign) {
      this.$router.push(
        this.$i18nRoute({
          name: 'rims-config-wheelDesign-overview',
          query: {
            brand: this.getBrand,
          },
        }),
      );
    } else if (!this.getBrand && !this.getSearch) {
      this.$router.push(
        this.$i18nRoute({
          name: 'rims-config-wheelDesign',
        }),
      );
    }


    if (this.getBrand) {
      this.currentColor = this.getWheelColor;

      this.isLoadingWheelDetails = true;
      this.$store.dispatch('rimconfigBase/getWheelDesigns', {
        manufacturers: this.getBrand,
        designs: this.getWheelDesign,
      })
        .then(() => {
          this.isLoadingWheelDetails = false;

          if (this.getGroupedWheelsByDesignAndColor.length !== 0) {
            let results;
            if (this.currentColor) {
              results = this.getGroupedWheelsByDesignAndColor.find((item) => item.color === this.currentColor).children;
            } else {
              results = this.getGroupedWheelsByDesignAndColor[0].children;
            }
            const adjustedResults = results.map((item) => {
              return {
                ...item,
                ...item.prices,
              };
            });
            this.tableItems = adjustedResults;
            setTimeout(() => {
              this.$refs.threeSixty.loadWheel();
            }, 0);

            const startIndex = this.getGroupedWheelsByDesignAndColor.findIndex((group) => group.color === this.currentColor);
            this.$refs.wheelOptionsSlider.$swiper.slideTo(startIndex);

            this.setInitSelectQuantity();
          }
        })
        .catch(() => {
          this.isLoadingWheelDetails = false;
        });
    } else if (this.getSearch) {
      this.isLoadingWheelDetails = true;
      this.$store.dispatch('rimconfigBase/getWheelsByQuery', {
        query: this.getSearch,
      })
        .then((res) => {
          this.isLoadingWheelDetails = false;

          const len = res.data.length;
          if (len === 0) {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.wheels-not-found'),
              message: this.$t('langkey.please-try-other-parameters'),
            });
            this.$router.push(this.$i18nRoute({
              name: 'rims-config-wheelDesign',
            }));
          } else {
            this.$store.dispatch('rimconfigBase/getWheelDesigns', {
              wheelTags: res.data.join(),
            })
              .then(() => {
                this.isLoadingWheelDetails = false;

                if (this.getGroupedWheelsByDesignAndColor.length !== 0) {
                  const results = this.getGroupedWheelsByDesignAndColor[0].children;
                  const adjustedResults = results.map((item) => {
                    return {
                      ...item,
                      ...item.prices,
                    };
                  });
                  this.tableItems = adjustedResults;
                  setTimeout(() => {
                    this.$refs.threeSixty.loadWheel();
                  }, 0);

                  // const startIndex = this.getGroupedWheelsByDesignAndColor.findIndex((group) => group.color === this.currentColor);
                  // this.$refs.wheelOptionsSlider.$swiper.slideTo(startIndex);

                  this.setInitSelectQuantity();
                }
              })
              .catch(() => {
                this.isLoadingWheelDetails = false;
              });
          }
        })
        .catch(() => {
          this.isLoadingWheelDetails = false;
        });
    }
  },
  beforeDestroy() {
    this.onResetFilter();
  },
  methods: {
    empty: _.isEmpty,
    setInitSelectQuantity() {
      this.tableItems.forEach((item) => {
        const itemKey = `${item.man}${item.ean}`;
        this.selectQuantity = {
          ...this.selectQuantity,
          [itemKey]: 4,
        };
      });
    },
    onViewCertificate(item) {
      this.isCertificate = true;

      this.isLoadingCertificate = true;
      this.isLoadingCertificateFilter = true;
      this.$store.dispatch('rimconfigWheelDesign/getWheelCertificates', {
        wheelDesign: item.design,
        wheelManufacturer: item.manufacturer,
        widths: item.width.toFixed(1),
        diameters: item.diameter,
      })
        .then(() => {
          this.isLoadingCertificate = false;
          this.isLoadingCertificateFilter = false;

          this.setInitFiltersOptions();
        })
        .catch(() => {
          this.isLoadingCertificate = false;
          this.isLoadingCertificateFilter = false;
        });
    },
    onHideCertificate() {
      this.isCertificate = false;
    },
    addToCart(item) {
      this.cartItems = [];
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const promise1 = this.$store.dispatch('rimconfigWheelDesign/getKitMatches', {
        mans: item.man,
      });
      const promise2 = this.$store.dispatch('rimconfigWheelDesign/getWheelAssigns', {
        wheelTag: item.wheelTag,
        assigns: item.assigns,
      });
      Promise.all([promise1, promise2])
        .then(() => {
          const wheelAssigns = this.wheelAssignByWheelTag[item.wheelTag].slice()
            .map((assignItem) => {
              return {
                ...assignItem,
                listPrice: item.prices.bestPrice,
              };
            });
          this.cartItems.push({
            ...item,
            imageTemplate: item.picturePerspective,
            description: item.description,
            articleId: item.man,
            quantity: this.selectQuantity[`${item.man}${item.ean}`] || 1,
            price: item.price || 0,
            suppliers: wheelAssigns,
          });
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$refs.addToCartDialog.show();
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    onSupplierAddToCart({ item, quantity, kitHan, carDescription }) {
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('cart/addCartItem', {
        id: item.id,
        quantity: quantity,
        context: {
          kitMan: kitHan,
          carDescription: carDescription,
        },
      })
        .then(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$router.push(this.$i18nRoute({
            name: 'cart',
          }));
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        });
    },
    onSubtractQuantityCart(item) {
      const index = this.cartItems.findIndex((cartItem) => cartItem.articleId === item.articleId);
      this.cartItems[index].quantity > 0 ? this.cartItems[index].quantity-- : 0;
    },
    onAddQuantityCart(item) {
      const index = this.cartItems.findIndex((cartItem) => cartItem.articleId === item.articleId);
      this.cartItems[index].quantity++;
    },
    onUpdateQuantityCart (item) {
      const index = this.cartItems.findIndex((cartItem) => cartItem.articleId === item.articleId);
      let newQty = item.quantity;
      if (!Number.isInteger(newQty) || newQty < 0) {
        newQty = 1;
      }
      this.cartItems[index].quantity = newQty;
    },

    onSlideChange(swiper) {
      this.currentSlide = swiper.realIndex;
    },
    onSlidePrev() {
      this.$refs.wheelOptionsSlider.$swiper.slidePrev();
    },
    onSlideNext() {
      this.$refs.wheelOptionsSlider.$swiper.slideNext();
    },
    onClickSlide(slide) {
      let clickedIndex = this.$refs.wheelOptionsSlider.$swiper.clickedIndex;
      this.$refs.wheelOptionsSlider.$swiper.slideTo(clickedIndex);

      this.currentColor = slide.color;

      if (this.getGroupedWheelsByDesignAndColor.length !== 0) {
        const results = this.getGroupedWheelsByDesignAndColor.find((item) => item.color === this.currentColor).children;
        const adjustedResults = results.map((item) => {
          return {
            ...item,
            ...item.prices,
          };
        });
        this.tableItems = adjustedResults;
        setTimeout(() => {
          this.$refs.threeSixty.loadWheel();
        }, 0);

        const startIndex = this.getGroupedWheelsByDesignAndColor.findIndex((group) => group.color === this.currentColor);
        this.$refs.wheelOptionsSlider.$swiper.slideTo(startIndex);

        this.setInitSelectQuantity();
      }
    },
    onClickBrand() {
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-wheelDesign',
      }));
    },
    onClickWheelsDesign() {
      if (this.getSearch) {
        this.$store.dispatch('rimconfigBase/getWheelsByQuery', {
          query: this.getSearch,
        })
          .then((res) => {
            const len = res.data.length;

            if (len === 0) {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.wheels-not-found'),
                message: this.$t('langkey.please-try-other-parameters'),
              });
              this.$router.push(this.$i18nRoute({
                name: 'rims-config-wheelDesign',
              }));
            } else {
              this.$store.dispatch('rimconfigBase/getWheelDesigns', {
                wheelTags: res.data.join(),
              })
                .then(() => {
                  this.$router.push(this.$i18nRoute({
                    name: 'rims-config-wheelDesign-overview',
                    query: {
                      search: this.getSearch,
                    },
                  }));
                });
            }
          });
      } else if (this.getBrand) {
        this.$store.dispatch('rimconfigBase/getWheelDesigns', {
          manufacturers: this.getBrand,
        })
          .then(() => {
            this.$router.push(this.$i18nRoute({
              name: 'rims-config-wheelDesign-overview',
              query: {
                brand: this.getBrand,
              },
            }));
          });
      }
    },
    getWheelImage(image) {
      return getImageBySize(image, 64, 64);
    },
    onClickCertificate(item) {
      window.open(item.certificateUrl, '_blank');
    },
    onConfirmAndShowCart() {
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const item = this.cartItems[0];
      this.$store.dispatch('cart/addCartItem', {
        id: item.articleId,
        quantity: item.quantity,
      })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    onContinueShopping() {
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const item = this.cartItems[0];
      this.$store.dispatch('cart/addCartItem', {
        id: item.articleId,
        quantity: item.quantity,
      })
        .then(() => {
          this.$router.push(this.$i18nRoute({
            name: 'cart',
          }));
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    setInitFiltersOptions() {
      this.selectFilterFieldsCertificate.forEach((field) => {
        this.filtersOptions = {
          ...this.filtersOptions,
          [field.key]: [
            ...new Set(this.wheelCertificates.map((item) => {
              return item[field.key];
            })),
          ]
            .map((item) => {
              return {
                label: item,
                value: item,
              };
            }),
        };
      });
    },
    onResetFilter() {
      Object.keys(this.selectedFilter).forEach((key) => {
        this.selectedFilter[key] = [];
      });

      this.setInitFiltersOptions();
    },
    onSelectFilter() {
      let eachFilterPossibleOption = {};
      let nonFilterOption = {};
      const filterDataKeys = Object.keys(this.selectedFilter).filter((key) => this.selectedFilter[key].length !== 0).map((key) => key);
      const nonFilterDataKeys = Object.keys(this.selectedFilter).filter((key) => this.selectedFilter[key].length === 0).map((key) => key);

      filterDataKeys.forEach((key) => {
        const customFilterData = {
          ...this.selectedFilter,
          [key]: [],
        };

        const customFilterItems = this.wheelCertificates.filter((item) => {
          let tmp = [];

          Object.keys(customFilterData).forEach((filterKey) => {
            if (customFilterData[filterKey].length === 0 ) {
              tmp.push(true);
            } else {
              const result = customFilterData[filterKey].some((filterEl) => {
                return item[filterKey] === filterEl;
              });

              tmp.push(result);
            }
          });

          return tmp.every((item) => item);
        });

        eachFilterPossibleOption = {
          ...eachFilterPossibleOption,
          [key]: customFilterItems,
        };
      });

      nonFilterDataKeys.forEach((nonFilter) => {
        nonFilterOption = {
          ...nonFilterOption,
          [nonFilter]: this.filteredWheelCertificates,
        };
      });

      this.selectFilterFieldsCertificate.forEach((field) => {
        if (eachFilterPossibleOption[field.key]) {
          this.filtersOptions = {
            ...this.filtersOptions,
            [field.key]: [...new Set(eachFilterPossibleOption[field.key].map((item) => item[field.key]))]
              .map((item) => {
                return {
                  label: item,
                  value: item,
                };
              }),
          };
        } else {
          this.filtersOptions = {
            ...this.filtersOptions,
            [field.key]: [...new Set(nonFilterOption[field.key].map((item) => item[field.key]))]
              .map((item) => {
                return {
                  label: item,
                  value: item,
                };
              }),
          };
        }
      });
    },
  },
};
</script>